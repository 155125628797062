import "./index.css";
import { useMediaQuery } from "react-responsive";
import { useAppDispatch } from "./store";
import { setDeviceType } from "./landingPage/redux/deviceTypeSlice";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import React from "react";
import { LandingPageRouting } from "./landingPage/landingPageRouting";
import ReactGA from "react-ga";

ReactGA.initialize("G-2ZLGFRRP7G"); // Replace with your own Tracking ID

const App = () => {
  //Start PageView Tracking
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const dispatch = useAppDispatch();
  const isNarrow = useMediaQuery({ query: "(max-width: 1100px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 880px)" });
  useEffect(() => {
    if (isMobile) {
      dispatch(setDeviceType("mobile"));
    } else if (!isNarrow) {
      dispatch(setDeviceType("wide"));
    } else {
      dispatch(setDeviceType("narrow"));
    }
  }, [dispatch, isMobile, isNarrow]);

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<LandingPageRouting isMobile={isMobile} />} />
        <Route path="*" element={<LandingPageRouting isMobile={isMobile} />} />
      </Routes>
    </HashRouter>
  );
};

export default App;
